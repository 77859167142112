<!--
/*
 * @Author:张天鹏
 * @Date: 2020-01-02 09:52:31
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-06-09 22:46:43
 */
 -->
<template>
  <div class="mainbody">
    <div class="search clearfix">
      <div class="searchlf searchlfbe">
        <md-field>
          <md-field-item
            placeholder="全部保险公司"
            v-model="formlist.baseid"
            :content="formlist.shortname"
            @click="showSelector"
            icon-position="right slots"
            solid
          />
          <md-icon name="arrow-down" slot="right" icon-svg></md-icon>
        </md-field>
        <div class="searchlfber">
          <img
            src="@/assets/jhs/img/down.png"
            alt=""
            v-if="!formlist.shortname"
          />
          <img
            src="@/assets/jhs/img/cuo.png"
            alt=""
            v-if="formlist.shortname"
            @click="cleardata"
          />
        </div>
        <md-selector
          v-model="isSelectorShow"
          :data="shortName"
          title="保险公司列表"
          max-height="320px"
          @confirm="onSelectorChoose"
          okText="确认"
        ></md-selector>
      </div>
      <div class="searchlf searchlfce">
        <md-field>
          <md-input-item
            clearable
            v-model="formlist.prodname"
            :maxlength="10"
            ref="input11"
            placeholder="请输入产品"
          >
            <md-icon name="search" slot="left"></md-icon>
          </md-input-item>
        </md-field>
      </div>
      <div class="searchfr">
        <md-field>
          <md-button @click="search">搜索</md-button>
        </md-field>
      </div>
    </div>
    <div class="cont clearfix" v-if="optfordata.length">
      <div class="conttop">
        <div class="conttoplf">
          <p>已选产品</p>
        </div>
        <div class="conttoprg">
          <md-button @click="deletedata"
            >清空<md-icon name="delete" size="md"></md-icon>
          </md-button>
        </div>
      </div>
      <md-scroll-view
        ref="scrollViewc"
        :scrolling-y="false"
        :touch-angle="80"
        :is-prevent="false"
        :auto-reflow="true"
      >
        <div
          class="contbom"
          :style="{
            width:
              optfordata.length < 4 ? 100 + '%' : optfordata.length * 25 + '%',
          }"
        >
          <div class="optfor" v-for="(item, index) of optfordata" :key="index">
            <div class="optfortp">
              <md-button
                icon="wrong"
                class="optfortpbtn"
                @click="removedata(item, index)"
              ></md-button>
              <img :src="item.iconhttp" alt="" />
            </div>
            <div class="optforbm">
              <p>{{ item.cpname }}</p>
            </div>
          </div>
        </div>
      </md-scroll-view>
    </div>
    <div
      class="list"
      v-if="reportList.length"
      :style="{ height: optfordata.length ? '61vh' : '87vh' }"
    >
      <p class="listp">全部产品</p>
      <md-scroll-view
        ref="scrollView"
        auto-reflow
        :scrolling-x="false"
        @end-reached="loadMore"
        :loading-text="
          totalpage < pageNo || totalpage == pageNo
            ? '没有要加载的数据啦...'
            : '正在加载中'
        "
      >
        <div class="product" v-for="(item, index) of reportList" :key="index">
          <div class="product-lf">
            <img :src="item.iconhttp" alt="" />
          </div>
          <div class="product-rg">
            <div class="product-rg-cen">
              <div class="product-rg-top">
                <md-agree
                  v-model="item.falg"
                  size="lg"
                  @change="onChange(item.falg, item, index, $event)"
                >
                </md-agree>
                <p>{{ item.cpname }}</p>
              </div>
              <div class="product-rg-bom">
                <p>{{ item.comname }}</p>
              </div>
            </div>
          </div>
        </div>
        <md-scroll-view-more slot="more" :is-finished="loading" />
      </md-scroll-view>
    </div>
    <md-result-page
      :img-url="require('@/assets/abd/image/null.png')"
      v-else
      subtext="要不然刷新试试？"
    />
    <div class="compare">
      <div class="comparelf">
        <p :class="this.optfordata.length < 2 ? 'comparelfpco' : ''">
          已选{{ this.optfordata.length }}个重疾产品
        </p>
      </div>
      <div class="comparerg">
        <md-button
          :type="this.optfordata.length < 2 ? 'disabled' : 'primary'"
          :style="{ backgroundColor: theme }"
          @click="comparergcl"
          >去对比</md-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {
  Selector,
  Field,
  FieldItem,
  Agree,
  Check,
  CheckGroup,
} from "mand-mobile";
import { brinkinglist, getjhsin } from "@/api/jhs/productlist/index";
import { getStorage, isAndroid } from "@/lib/util";
import config from "@/config";
import loadMore from "@/mixins/loadmore";
const { cvu } = config;
export default {
  mixins: [loadMore],
  components: {
    [Selector.name]: Selector,
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [Agree.name]: Agree, // 单选
    [Check.name]: Check, // 多选
    [CheckGroup.name]: CheckGroup, // 多选
  },
  data() {
    return {
      formlist: {
        baseid: "",
        prodname: "",
      },
      personNum: 1,
      ossurl: "",
      pageNo: 1,
      pageSize: 6,
      loading: false,
      reportList: [],
      totalpage: 0,
      isSelectorShow: false,
      selectorValue: "",
      shortName: [],
      prods: [],
      optfordata: [], // 选中数据
    };
  },
  created() {
    this.theme = getStorage("theme", "");
    this.ossurl = cvu;
    this.getData();
    getjhsin({
      comid: getStorage("u_s", "").comid,
      type: "DB",
    }).then((res) => {
      for (let item in res.data.data) {
        res.data.data[item].data.forEach((it, ind) => {
          this.shortName.push({ value: it.baseid, text: it.shortname });
        });
        // res.data.data[item].text = res.data.data[item].shortname;
        // res.data.data[item].value = res.data.data[item].baseid;
      }
    });
  },
  watch: {
    "$route.name"(nVal) {
      const judgedr = navigator.userAgent.indexOf("dongrui");
      if (isAndroid() && judgedr != -1) {
        window.JYTitleInterface.openPage();
      }
    },
  },
  methods: {
    async getData(isInit = true) {
      let { pageNo, pageSize } = this;
      if (!isInit) {
        this.totalpage > pageNo || this.totalpage == pageNo
          ? (this.pageNo = this.totalpage)
          : (this.pageNo = ++pageNo);
        this.$refs.scrollView.finishLoadMore();
      }
      let res = await brinkinglist({
        page: pageNo,
        size: pageSize,
        baseid: this.formlist.baseid,
        prodname: this.formlist.prodname,
      });
      if (pageNo > 1) {
        for (let item in res.data.data.rows) {
          for (let ic in this.optfordata) {
            if (
              res.data.data.rows[item].cpcode === this.optfordata[ic].cpcode
            ) {
              res.data.data.rows[item].falg = this.optfordata[ic].falg;
            }
          }
          this.reportList.push(res.data.data.rows[item]);
        }
        this.total = res.data.data.total;
        this.totalPage = res.data.data.totalpage;
      } else {
        this.reportList = res.data.data.rows;
        for (let item in this.reportList) {
          for (let ic in this.optfordata) {
            if (this.reportList[item].cpcode === this.optfordata[ic].cpcode) {
              this.reportList[item].falg = this.optfordata[ic].falg;
            }
          }
        }
        this.total = res.data.data.total;
        this.totalPage = res.data.data.totalpage;
      }
    },
    comparergcl() {
      let cpcodes = [];
      for (let item of this.optfordata) {
        cpcodes.push(item.cpcode);
      }
      this.$router.push({
        path: "/comparerg",
        query: {
          cpcodes: cpcodes,
          fromwhere: this.$route.query.fromwhere
            ? this.$route.query.fromwhere
            : "",
        },
      });
    },
    deletedata() {
      // 清空选好的数据
      this.optfordata = [];
      for (let item in this.reportList) {
        this.reportList[item].falg = false;
      }
      this.cleardata();
    },
    search() {
      this.pageNo = 1;
      this.getData(this);
      this.loading = false;
      this.$refs.scrollView.finishLoadMore();
    },
    cleardata() {
      this.formlist = {
        baseid: "",
        prodname: "",
      };
      this.pageNo = 1;
      this.getData();
      this.loading = false;
      this.$refs.scrollView.finishLoadMore();
    },
    removedata(val, index) {
      for (let item in this.reportList) {
        if (this.reportList[item].cpcode === val.cpcode) {
          this.reportList[item].falg = false;
        }
      }
      this.optfordata.splice(index, 1);
    },
    onChange(checked, children, index) {
      if (checked) {
        this.optfordata.push(children);
      } else {
        for (let item in this.optfordata) {
          if (this.optfordata[item].cpcode === children.cpcode) {
            this.optfordata.splice(item, 1);
          }
        }
      }
    },
    showSelector() {
      this.isSelectorShow = true;
    },
    onSelectorChoose({ text, value }) {
      this.formlist.shortname = text;
      this.formlist.baseid = value;
      this.pageNo = 1;
      this.getData();
      this.loading = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
.mainbody {
  background-color: #F7F6FB;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.product {
  height: 250px;
  background: #fff;
  border-bottom: 20px solid #eee;
}

.product-lf {
  float: left;
  width: 30vw;
  height: 100%;
  text-align: center;
  position: relative;
}

.product-lf img {
  border-radius: 5px;
  position: absolute; // 相对定位
  width: 180px;
  height: 160px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; // 使其垂直居中
}

.product-rg {
  float: left;
  width: 70vw;
  height: 100%;
  position: relative;
}

.product-rg-cen {
  position: absolute; // 相对定位
  height: 160px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; // 使其垂直居中
}

.product-rg-top {
  height: 50%;
}

.product-rg-top p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 3%;
}

.product-rg-top .md-agree {
  float: right;
  display: table-cell;
  vertical-align: middle;
  padding: 20px 0 20px 30px;
}

.product-rg-bom p {
  padding-top: 3%;
  font-size: 33px;
  color: #808080;
}

.searchlf /deep/ .md-field-item-content::before {
  border-bottom: 0px;
}

.searchlf /deep/ .md-field-item-placeholder {
  color: #000;
  font-size: 0.33rem;
}

.list {
  height: 100%;
}

.clearfix:after, .clearfix:before {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

// .clearfix {
// @css {
// *
// }
// zoom: 1;
// }
.list /deep/ .scroll-view-more {
  padding-bottom: 65px;
}

.listp {
  height: 100px;
  line-height: 100px;
  padding-left: 62.5px;
  font-weight: 600;
  background: #fff;
  border-bottom: 1px solid #f6f6f6;
}

.cont {
  height: 25vh;
  background: #f6f6f6;
}

.conttop {
  margin-top: 20px;
  height: 100px;
  width: 100%;
  display: flex;
}

.conttopfl {
  width: 50%;
}

.conttoplf p {
  height: 100px;
  line-height: 100px;
  padding-left: 62.5px;
  font-weight: 600;
}

.conttoprg {
  width: 77%;
}

.conttoprg .md-button {
  float: right;
}

.conttoprg .md-button.block {
  width: 150px;
}

.conttoprg .md-button.default {
  background: none;
}

.contbom {
  height: 100%;
  display: flex;
}

.optfor {
  width: 250px;
  height: 250px;
  background: #fff;
  margin: 0 15px;
  border-radius: 10px;
  box-shadow: 0 0px 21px -2px #ccc;
}

.optfortp {
  padding-top: 35px;
  height: 50%;
  text-align: center;
  position: relative;
}

.optfortp img {
  height: 100px;
  width: 100px;
}

.optforbm {
  width: 90%;
  padding-top: 35px;
  margin: 0 auto;
  height: 50px;
}

.optforbm p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}

.optfortpbtn {
  position: absolute;
  right: 0;
  top: 0;
}

.optfortp .md-button {
  padding: 0;
  height: 60px;
}

.optfortp .md-button.block {
  width: 50px;
}

.compare {
  width: 100%;
  height: 6vh;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background: #fff;
  z-index: 5;
}

.comparelf p {
  line-height: 6vh;
  padding-left: 62.5px;
}

.comparelfpco {
  color: #e2e4ea;
}

.comparerg {
  width: 40%;
}

.comparerg .md-button.block {
  height: 100%;
}

.searchlfber {
  width: 20%;
  padding: 40px 0 40px 0px;
  text-align: center;
  position: relative;
  background: #fff;
}

.searchlfber img {
  width: 40px;
  height: 40px;
  position: absolute; // 相对定位
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto 0; // 使其垂直居中
}

.search {
  background: #fff;
}

.search .searchlf {
  float: left;
  width: 60%;
  height: 100%;
}

.search .searchlfbe {
  width: 30%;
}

.searchfr {
  float: left;
  width: 10%;
  height: 100%;
}

.searchfr .md-field {
  padding: 0.4rem 0rem;
}

.searchfr .md-field /deep/ .md-button-content {
  color: #238acf;
}

.searchlfce /deep/ .md-field-content {
  border-radius: 50px;
  background: #f6f6f6;
}

.searchlfce /deep/ .md-field-item-left {
  padding-left: 30px;
}

.searchlfce .md-field {
  padding: 40px 40px 40px 0px;
}

.searchlfbe {
  display: flex;
}

.searchlfbe /deep/ .md-field {
  width: 80%;
  padding: 40px 0px 40px 40px;
}

.searchlf /deep/ .md-field-item-control {
  width: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .md-icon.icon-font.md {
  font-size: 45px;
  padding-right: 10px;
  background: #f6f6f6;
}
</style>
